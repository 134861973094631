import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import moment from 'moment';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  fetchConsumes,
  getConsumes,
  resetConsumeData,
} from '../../app/reducers/Consume/consumeSlice';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PaymentModel from '../Product/PaymentModel';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';

const ViewConsume = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paymentModel, setPaymentModel] = useState(false);
  const [editData, setData] = useState(null);
  const [type, setType] = useState('challan');
  const { consume, loading: consumeLoading } = useSelector(getConsumes);
  const [productData, setProductData] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchConsumeByPayments = async data => {
    setLoading(true);
    let string = QueryString.stringify(data);
    let resp = await authAxiosInstance.get(`/consume/payments?${string}`);
    console.log(resp);
    if (resp?.data?.data) {
      setProductData(resp?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let filteringData = {
      type: type,
      page: 1,
      populate: true,
    };
    fetchConsumeByPayments(filteringData);
  }, [page, type]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + productData.pagingCounter;
      },
    },
    {
      Header: 'Date',
      Cell: ({ row }) => {
        return row?.original?.createdAt
          ? moment(row?.original?.createdAt).format('DD/MM/YYYY')
          : 'No Date Found';
      },
    },
    {
      Header: 'Consume No.',
      Cell: ({ row }) => {
        return (
          <Link to={`${row?.original._id}`}>
            <div className="text-blue-600 underline cursor-pointer">
              {row?.original?.consumeNumber}
            </div>
          </Link>
        );
      },
    },
    {
      Header: 'Customer Name',
      accessor: 'customerName',
    },
    {
      Header: 'Customer Phone',
      accessor: 'customerPhone',
    },
    {
      Header: 'Location',
      accessor: 'location.name',
    },
    {
      Header: 'Total Amount',
      Cell: ({ row }) => {
        return (
          <div className="text-blue">
            {row.original?.products?.reduce((acc, item) => acc + Number(item.total_price), 0) || 0}
          </div>
        );
      },
    },
    {
      Header: 'Remaining Amount',
      Cell: ({ row }) => {
        return (
          <div className="text-blue">
            {Math.ceil(
              row.original?.products?.reduce((acc, item) => acc + Number(item.total_price), 0) || 0
            ) - row?.original?.payments.reduce((acc, item) => acc + item.amount, 0)}
          </div>
        );
      },
    },
    {
      Header: 'Action',
      Cell: ({ row }) => {
        return (
          <div
            className="flex flex-row gap-3"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {row?.original?.payments?.reduce((acc, item) => acc + item.amount, 0) >=
            Math.ceil(row?.original?.totalAmount) ? (
              '---'
            ) : (
              <PrimaryButton
                onClick={e => {
                  e.stopPropagation();
                  setData({
                    ...row.original,
                    _id: row.original.consumeNumber,
                    manufacturer: { _id: row.original?.customerId },
                  });
                  setPaymentModel(true);
                }}
              >
                Pay Amount
              </PrimaryButton>
            )}
          </div>
        );
      },
    },
  ];

  const dataMemoization = useMemo(() => productData?.docs ?? [], [productData]);
  const columnsMemoization = useMemo(() => columns, [columns]);

  const debouncingSearch = useCallback(
    _.debounce(search => {
      let filteringData = {
        type: type,
        search: search,
        populate: true,
        page,
      };
      dispatch(fetchConsumes(filteringData));
    }, 300),
    []
  );
  return (
    <PageWithCard heading="View Consumes">
      <PaymentModel
        isOpen={paymentModel}
        setIsOpen={setPaymentModel}
        data={editData}
        type="consume"
        fetchOrders={fetchConsumeByPayments}
      />
      <div className="flex gap-3 mb-2">
        <SecondaryButton onClick={() => setType('challan')}>View Challan</SecondaryButton>
        <SecondaryButton onClick={() => setType('consumption')}>View Consumption</SecondaryButton>
      </div>
      {type == 'challan' && (
        <TableWithHeadingAndGlobalSearch
          loading={consumeLoading}
          heading={'View Challan'}
          columns={columnsMemoization}
          data={dataMemoization}
          searchFunction={value => {
            debouncingSearch(value);
          }}
        />
      )}
      {type == 'consumption' && (
        <TableWithHeadingAndGlobalSearch
          loading={consumeLoading}
          heading={'View Consumption'}
          columns={columnsMemoization}
          data={dataMemoization}
          searchFunction={value => {
            debouncingSearch(value);
          }}
        />
      )}
      <PaginationClassic paginationDetails={productData} setPage={setPage} />
    </PageWithCard>
  );
};

export default ViewConsume;
