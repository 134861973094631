import { createSlice } from '@reduxjs/toolkit';
import { resetData } from '../../generators/generateReducers';
import { generateStateSingle } from '../../generators/generateState';
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from '../../generators/generateThunks';
const SLICE_NAME = 'consume';

export const fetchConsumes = fetchData.generateThunk(SLICE_NAME, SLICE_NAME);
export const editConsume = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchConsume = fetchEditData.generateThunk(SLICE_NAME, SLICE_NAME);
export const deleteConsume = deleteData.generateThunk(SLICE_NAME, SLICE_NAME);
export const createConsume = createData.generateThunk(SLICE_NAME, SLICE_NAME);

const consumeSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    resetConsumeData: resetData(generateStateSingle(SLICE_NAME)),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchConsumes, SLICE_NAME),
    ...createData.generateExtraReducers(createConsume, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteConsume, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchConsume, SLICE_NAME),
    ...editData.generateExtraReducers(editConsume, SLICE_NAME),
  },
});

export const getConsumes = state => state.consume;
export const { setEditId, resetConsumeData } = consumeSlice.actions;
export default consumeSlice.reducer;
