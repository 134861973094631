import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getUsers } from "../../app/reducers/Users/usersSlice";
import {
  fetchEmployee,
  getEmployees,
} from "../../app/reducers/Users/EmployeeSlice";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import { routes } from "../../utils/routes";

const AccessControl = () => {
  const { id } = useParams();
  const [routesGroup, setRoutesGroup] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [userPageAccess, setUserPageAccess] = useState({});
  const { elementEditData: editUserData } = useSelector(getEmployees);
  //   const { editUserData } = useSelector(getUsers);

  const dispatch = useDispatch();

  const computePageAccess = async () => {
    try {
      const stringData = QueryString.stringify({ user: id });
      const resp = await authAxiosInstance.get(`/accessControl?${stringData}`);
      setUserPageAccess({ ...resp?.data?.data?.docs[0] });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const computeInitialValue = (userObj, userAccessObj) => {
    if (userAccessObj) {
      return {
        ...userObj,
        ...userAccessObj,
        pagesAllowed: Array.isArray(userAccessObj.pagesAllowed)
          ? userAccessObj.pagesAllowed
          : [],
      };
    } else {
      return {
        ...userObj,
        pagesAllowed: [],
      };
    }
  };

  const computeRouteGroup = () => {
    const groupData = routes.reduce((acc, crr) => {
      if (crr.sidebarType === "single") {
        acc.push({
          type: crr.sidebarType,
          Icon: crr.Icon,
          label: crr.label,
          link: crr.route,
          allowedRoles: crr.allowedRoles,
          order: crr.order,
          pageName: crr.pageName,
        });
      } else if (crr.sidebarType === "group") {
        const index = acc.findIndex(
          (ele) => ele.heading === crr.groupName && ele.type === "group"
        );
        if (index === -1) {
          acc.push({
            type: crr.sidebarType,
            Icon: crr.Icon,
            heading: crr.groupName,
            align: "right",
            order: crr.order,
            links: [
              {
                label: crr.label,
                allowedRoles: crr.allowedRoles,
                link: crr.route,
                pageName: crr.pageName,
              },
            ],
          });
        } else {
          acc[index].links.push({
            label: crr.label,
            allowedRoles: crr.allowedRoles,
            link: crr.route,
            pageName: crr.pageName,
          });
        }
      }
      return acc;
    }, []);
    groupData.sort((a, b) => a.order - b.order);
    setRoutesGroup(groupData);
  };

  useEffect(() => {
    // dispatch(getEditUserData(id));
    dispatch(fetchEmployee({ _id: id }));
    computePageAccess();
    computeRouteGroup();
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: computeInitialValue(
      {
        ...editUserData,
        name: `${editUserData?.firstName} ${editUserData?.lastName}`,
      },
      userPageAccess
    ),
    onSubmit: async (values) => {
      try {
        if (!userPageAccess.user) {
          const { _id, pagesAllowed } = values;
          const sortPagesAllowed = [...pagesAllowed].sort();
          await authAxiosInstance
            .post(`/accessControl`, {
              user: _id,
              pagesAllowed: sortPagesAllowed,
            })
            .then((res) => {
              if (res.data.success) {
                toast.success("Successfully give pages access to user");
              }
            });
        } else {
          const { user, pagesAllowed } = values;
          const sortPagesAllowed = [...pagesAllowed].sort();
          await authAxiosInstance
            .post(`/accessControl`, {
              user: user,
              pagesAllowed: sortPagesAllowed,
            })
            .then((res) => {
              if (res.data.success) {
                toast.success("Successfully give pages access to user");
              }
            });
        }
      } catch (error) {
        toast.error(`Error : ${error.message}`);
      }
    },
  });

  const allPages = routes
    .filter((ele) => ele.pageName)
    .map((ele) => ele.pageName);

  useEffect(() => {
    const selectedPages = formik?.values?.pagesAllowed || [];
    if (selectedPages.length === allPages.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [formik?.values?.pagesAllowed]);

  const handleCheckBoxChange = (pageName) => {
    const pagesAllowed = Array.isArray(formik?.values?.pagesAllowed)
      ? formik?.values?.pagesAllowed
      : [];

    if (pagesAllowed.includes(pageName)) {
      const updatePage = pagesAllowed.filter((page) => page !== pageName);
      formik.setFieldValue("pagesAllowed", updatePage);
    } else {
      const updateAccess = [...pagesAllowed, pageName];
      formik.setFieldValue("pagesAllowed", updateAccess);
    }
  };

  const handleSelectAll = () => {
    if (formik.values.pagesAllowed.length === allPages.length) {
      formik.setFieldValue("pagesAllowed", []);
    } else {
      formik.setFieldValue("pagesAllowed", allPages);
    }
  };

  return (
    <PageWithCard heading="Access Control">
      <form onSubmit={formik.handleSubmit} className="">
        <h1 className="mb-4 font-semibold text-xl text-indigo-500">
          Employee Details :
        </h1>
        <div className="flex flex-col md:flex-row items-center justify-center gap-4 mb-4">
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              name="name"
              label="Name"
              readOnly
            />
          </div>
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              name="username"
              label="Phone No."
              readOnly
            />
          </div>
          <div className="w-full">
            <FormikInputGroup
              formik={formik}
              name="role"
              label="Role"
              readOnly
            />
          </div>
        </div>

        <h1 className="mb-4 font-semibold text-xl text-indigo-500">
          Pages Access :
        </h1>
        <div className="mb-4">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={() => handleSelectAll()}
            />
            Select All
          </label>
        </div>
        <div className=" mb-4">
          {routesGroup?.map((ele, i) => {
            if (ele.type === "single") {
              return (
                <div className="" key={ele.pageName}>
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={formik?.values?.pagesAllowed?.includes(
                        ele.pageName
                      )}
                      onChange={() => handleCheckBoxChange(ele.pageName)}
                    />
                    {ele.pageName}
                  </label>
                </div>
              );
            } else if (ele.type === "group") {
              return (
                <>
                  <h2 className="font-semibold text-lg text-indigo-500 mt-4">
                    {ele?.heading}
                  </h2>
                  {ele.links.map((child) => (
                    <div className="" key={child.pageName}>
                      <label className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          checked={formik?.values?.pagesAllowed?.includes(
                            child.pageName
                          )}
                          onChange={() => handleCheckBoxChange(child.pageName)}
                        />
                        {child.pageName}
                      </label>
                    </div>
                  ))}
                </>
              );
            }
          })}
        </div>
        <div>
          <PrimaryButton type="submit">Give Access</PrimaryButton>
        </div>
      </form>
    </PageWithCard>
  );
};

export default AccessControl;
