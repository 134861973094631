import { createSlice } from "@reduxjs/toolkit";
import { generateSetEditId } from "../../generators/generateReducers";
import { generateStateSingle } from "../../generators/generateState";
import {
  createData,
  deleteData,
  editData,
  fetchData,
  fetchEditData,
} from "../../generators/generateThunks";
const SLICE_NAME = "accessControl";

export const fetchAccessControls = fetchData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const editAccessControl = editData.generateThunk(SLICE_NAME, SLICE_NAME);
export const fetchAccessControl = fetchEditData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const deleteAccessControl = deleteData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);
export const createAccessControl = createData.generateThunk(
  SLICE_NAME,
  SLICE_NAME
);

const accessControlSlice = createSlice({
  name: SLICE_NAME,
  initialState: generateStateSingle(SLICE_NAME),
  reducers: {
    setEditId: generateSetEditId(),
  },
  extraReducers: {
    ...fetchData.generateExtraReducers(fetchAccessControls, SLICE_NAME),
    ...createData.generateExtraReducers(createAccessControl, SLICE_NAME),
    ...deleteData.generateExtraReducers(deleteAccessControl, SLICE_NAME),
    ...fetchEditData.generateExtraReducers(fetchAccessControl, SLICE_NAME),
    ...editData.generateExtraReducers(editAccessControl, SLICE_NAME),
  },
});

export const getAccessControls = (state) => state.accessControl;
export const { setEditId } = accessControlSlice.actions;
export default accessControlSlice.reducer;
