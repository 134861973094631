import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import moment from 'moment';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  fetchConsumes,
  getConsumes,
  resetConsumeData,
} from '../../app/reducers/Consume/consumeSlice';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { getMediaLibrary } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import PaymentModel from '../Product/PaymentModel';

const ViewConsumeProducts = () => {
  const [page, setPage] = useState(1);

  const { baseUrl } = useSelector(getMediaLibrary);
  const [type, setType] = useState('challan');
  const { consume, loading: consumeLoading } = useSelector(getConsumes);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetConsumeData());
    };
  }, []);

  useEffect(() => {
    let filteringData = {
      _id: id,
      populate: true,
    };
    dispatch(fetchConsumes(filteringData));
  }, [page, id]);

  const columns = [
    {
      Header: 'SRNO',
      Cell: prop => {
        const { data, row } = prop;
        return row.index + consume.pagingCounter;
      },
    },
    {
      Header: 'Photo',
      Cell: prop => {
        const { data, row } = prop;
        return (
          <>
            <div style={{ position: 'relative' }}>
              {_.isEmpty(row?.original?.product?.photo) ? (
                'No Image'
              ) : (
                <img
                  src={`${baseUrl}${row.original?.product?.photo}`}
                  style={{ width: '100px', height: '100px' }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: 'Product Name',
      accessor: 'product.name',
    },
    {
      Header: 'Parent Sku',
      accessor: 'product.parentSku',
    },
    {
      Header: 'Master Sku',
      accessor: 'product.masterSku',
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'MRP',
      accessor: 'MRP',
    },
    {
      Header: 'Selling Price',
      accessor: 'product.sellingPrice',
    },
    {
      Header: 'Total Price',
      Cell: ({ row }) => {
        return <div className="text-blue">{row.original?.total_price}</div>;
      },
    },
    // {
    //   Header: 'Action',
    //   Cell: ({ row }) => {
    //     return (
    //       <div
    //         className="flex flex-row gap-3"
    //         onClick={e => {
    //           e.stopPropagation();
    //         }}
    //       >
    //         {row?.original?.payments?.reduce((acc, item) => acc + item.amount, 0) >=
    //         Math.ceil(row?.original?.totalAmount) ? (
    //           '---'
    //         ) : (
    //           <PrimaryButton
    //             onClick={e => {
    //               e.stopPropagation();
    //               setData(row.original);
    //               setPaymentModel(true);
    //             }}
    //           >
    //             Pay Amount
    //           </PrimaryButton>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  const dataMemoization = useMemo(() => consume?.docs?.[0]?.products ?? [], [consume]);
  const columnsMemoization = useMemo(() => columns, [columns]);

  const debouncingSearch = useCallback(
    _.debounce(search => {
      let filteringData = {
        type: type,
        search: search,
        populate: true,
        page,
      };
      dispatch(fetchConsumes(filteringData));
    }, 300),
    []
  );

  return (
    <PageWithCard heading="View Consume Products">
      <TableWithHeadingAndGlobalSearch
        loading={consumeLoading}
        columns={columnsMemoization}
        data={dataMemoization}
        searchFunction={value => {
          debouncingSearch(value);
        }}
      />

      <PaginationClassic paginationDetails={consume} setPage={setPage} />
    </PageWithCard>
  );
};

export default ViewConsumeProducts;
