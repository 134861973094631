import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PAYMENT_METHOD } from '../../utils/dropdownOptions';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { createCreditbook, fetchCreditbooks } from '../../app/reducers/Creditbook/creditbookSlice';
import { createCashbook } from '../../app/reducers/Cashbook/cashbookSlice';
import { generateOptions } from '../../utils/Utils';
import { fetchLocations, getLocation } from '../../app/reducers/Location/locationSlice';
import { number, object, string } from 'yup';
import { fetchBanks, getBanks } from '../../app/reducers/Bank/bankSlice';
import { editSaleOrder, fetchSaleOrders } from '../../app/reducers/SaleOrder/saleOrderSlice';
import { createBankbook } from '../../app/reducers/Bankbook/bankbookSlice';
import { Loader } from 'react-feather';
import { editSale } from '../../app/reducers/Sale/saleSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';

const PaymentModel = ({
  isOpen,
  setIsOpen,
  data,
  fetchOrders,
  formikData,
  type = 'directReceiveOrder',
}) => {
  const saleLocationInfoId = localStorage.getItem('SaleLocationInfoId');
  const { location, loading: locationLoading } = useSelector(getLocation);
  const { bank, loading: bankLoading } = useSelector(getBanks);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchBanks());
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user: data?.manufacturer?._id,
      challanNumber: data?._id,
      method: '',
      amount: '',
      location: saleLocationInfoId ? JSON.parse(saleLocationInfoId) : '',
    },
    validationSchema: object({
      location: string().when('method', {
        is: 'cash',
        then: string().required('Location is required for cash transaction'),
        otherwise: string(),
      }),
      method: string().required(),
      user: string().required(),
      amount: number().min(1).required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        let finalData = {
          transaction_type: 'debit',
          amount: values.amount,

          location: values.location,
          method: values.method,
          user: values.user,
          type: type,
        };
        if (type === 'consume') {
          finalData['consumeNumber'] = Number(values?.challanNumber);
        } else {
          finalData['challanNumber'] = values?.challanNumber;
        }
        let resp = await authAxiosInstance.post('/payments', finalData);
        setIsOpen(false);
        let orderData;
        if (type == 'purchaseOrder') {
          orderData = {
            createdAt: {
              $gt: formikData?.values?.from,
              $lt: formikData?.values?.to,
            },
            populate: true,
            page: 1,
          };
        } else if (type === 'consume') {
          orderData = {
            type: 'challan',
            page: 1,
            populate: true,
          };
        } else {
          orderData = {
            status: 'directReceiveOrder',
            createdAt: {
              $gt: formikData?.values?.from,
              $lt: formikData?.values?.to,
            },
            populate: true,
            page: 1,
          };
        }
        fetchOrders(orderData);
        resetForm({
          user: values.user,
        });
      } catch (error) {
        console.log('Error', error);
      }
    },
  });
  return (
    <ModalBasic modalOpen={isOpen} setModalOpen={setIsOpen} title={'Receive Amount'}>
      <form onSubmit={formik.handleSubmit} className="p-4 flex flex-col gap-3">
        <FormikInputGroup label="Customer" name={'user'} formik={formik} readOnly />
        <FormikInputGroup label="Challan No." name={'challanNumber'} formik={formik} readOnly />
        <FormikSelectGroup
          name="location"
          label="Location / Store"
          formik={formik}
          options={generateOptions({
            array: location?.docs ?? [],
            valueField: '_id',
            labelField: 'name',
          })}
        />{' '}
        <FormikSelectGroup
          label="Method"
          name={'method'}
          formik={formik}
          options={[
            ...(bank.docs ?? []).map(item => ({
              value: item._id,
              label: item.name,
            })),
            ...PAYMENT_METHOD.filter(item => item.value !== 'credit'),
          ]}
        />
        <FormikInputGroup type={'number'} name={'amount'} label="Amount" formik={formik} />
        <div>
          {formik.isSubmitting ? (
            <div className="my-auto">
              <Loader className="animate-spin " />
            </div>
          ) : (
            <PrimaryButton disabled={formik.isSubmitting} type="submit">
              Submit
            </PrimaryButton>
          )}
        </div>
      </form>
    </ModalBasic>
  );
};

export default PaymentModel;
