import React, { useState, useEffect, useRef } from "react";
import {
  Archive,
  Briefcase,
  Database,
  Edit,
  Mail,
  User,
  UserPlus,
  Users,
  Clipboard,
  Monitor,
} from "react-feather";
import { NavLink } from "react-router-dom";
import SidebarGroup from "../../components/infrastructure/SidebarGroup";
import SidebarSingle from "../../components/infrastructure/SidebarSingle";
import SidebarTopText from "../../components/infrastructure/SidebarTopText";
import SidebarLinks from "./SidebarLinks";
import { useSelector } from "react-redux";
import { getAuth } from "../../app/reducers/Auth/authSlice";
import { routes } from "../../utils/routes";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const [routesGroup, setRoutesGroup] = useState([]);
  const { user } = useSelector(getAuth);
  const { pagesAllowed } = user;
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  const computeRouteGroup = () => {
    const groupData = routes.reduce((acc, crr) => {
      if (!["admin", "org_admin"].includes(user?.role)) {
        if (!pagesAllowed.includes(crr.pageName)) {
          return acc;
        }
      }
      if (crr.sidebarType === "single") {
        acc.push({
          type: crr.sidebarType,
          Icon: crr.Icon,
          label: crr.label,
          link: crr.route,
          allowedRoles: crr.allowedRoles,
          order: crr.order,
          pageName: crr.pageName,
        });
      } else if (crr.sidebarType === "group") {
        const index = acc.findIndex(
          (ele) => ele.heading === crr.groupName && ele.type === "group"
        );
        if (index === -1) {
          acc.push({
            type: crr.sidebarType,
            Icon: crr.Icon,
            heading: crr.groupName,
            align: "right",
            order: crr.order,
            links: [
              {
                label: crr.label,
                allowedRoles: crr.allowedRoles,
                link: crr.route,
                pageName: crr.pageName,
              },
            ],
          });
        } else {
          acc[index].links.push({
            label: crr.label,
            allowedRoles: crr.allowedRoles,
            link: crr.route,
            pageName: crr.pageName,
          });
        }
      }
      return acc;
    }, []);
    groupData.sort((a, b) => a.order - b.order);
    setRoutesGroup(groupData);
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const handleLinkClick = () => {
    if (window.innerWidth < 1024) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  };

  useEffect(() => {
    computeRouteGroup();
  }, []);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          {/* <NavLink end to="/" className="block">
            <div className="flex items-center">
              <img
                className="w-full sidebar-expanded:w-1/3 "
                src="https://res.cloudinary.com/blinklink-solutions/image/upload/v1562090581/60292_cr_cr_bmfn3c.png"
              />
              <p className="hidden sidebar-expanded:block text-base  text-white">
                Blinklink Solutions
              </p>
            </div>
          </NavLink> */}
        </div>

        {/* Links */}
        {/* <div className="space-y-8">
         
          <div>
            <SidebarLinks
              setSidebarExpanded={setSidebarExpanded}
              sidebarExpanded={sidebarExpanded}
              sidebarOpen={sidebarOpen}
              handleLinkClick={handleLinkClick}
            />
          </div>
        </div> */}

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              <SidebarTopText
                // text="Application"
                sidebarOpen={sidebarOpen}
              />
              {routesGroup.map((ele, i) => {
                return (
                  <React.Fragment key={i}>
                    {
                      {
                        single: (
                          <SidebarSingle
                            name={ele.label}
                            Icon={<ele.Icon />}
                            activePath={ele.link}
                            allowedRoles={ele.allowedRoles}
                            sidebarOpen={sidebarOpen}
                          />
                        ),
                        group: (
                          <SidebarGroup
                            heading={ele.heading}
                            Icon={<ele.Icon />}
                            links={ele.links}
                            sidebarExpanded={sidebarExpanded}
                            setSidebarExpanded={setSidebarExpanded}
                          />
                        ),
                      }[ele.type]
                    }
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
