import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteLead,
  fetchLeads,
  getLeads,
} from "../../app/reducers/Lead/leadSlice";
import moment from "moment-timezone";
import { ArrowUpRight, Download, Edit, Eye, Mail, Trash } from "react-feather";
import whatsappIcon from "../../images/whatsapp_PNG.png";
import { useNavigate } from "react-router-dom";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import MessageTextModal from "./MessageTextModal";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import SecondaryButton from "../../components/infrastructure/Buttons/SecondaryButton";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import { exportCsv, generateOptions } from "../../utils/Utils";
import {
  fetchStatuses,
  getStatuses,
} from "../../app/reducers/Status/statusSlice";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import PaginationClassic from "../../components/infrastructure/pagination/PaginationClassic";
import ViewLeadInDetails from "./ViewLeadInDetails";
import FormikAsyncMultiSelect from "../../components/formik/FormikAsyncMultiSelect";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { useFormik } from "formik";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import {
  LEAD_FILTER_OPTION,
  LEAD_MEETING_SCHEDULE,
} from "../../utils/dropdownOptions";
import {
  fetchEmployees,
  getEmployees,
} from "../../app/reducers/Users/EmployeeSlice";
import { fetchGroups, getGroups } from "../../app/reducers/Group/groupSlice";

const ViewLead = () => {
  const [page, setPage] = useState(1);
  const [isDownloading, setIsDownloading] = useState(false);
  const [activeBtnIndex, setActiveBtnIndex] = useState(-1);
  const [linkOption, setLinkOption] = useState({});
  const [openMessageBox, setOpenMessageBox] = useState(false);
  const [currentStatusState, setCurrentStatusState] = useState(null);
  const [leadNotesData, setLeadNotesData] = useState({});
  const [isLeadNotesModalOpen, setIsLeadNotesModalOpen] = useState(false);

  const { lead, loading } = useSelector(getLeads);
  const { status, loading: statusLoading } = useSelector(getStatuses);
  const { employees, loading: employeesLoading } = useSelector(getEmployees);
  const { group, loading: groupLoading } = useSelector(getGroups);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //setDateRangeQuery
  function setDateRangeQuery(fromValue, toValue, queryKey, query) {
    if (fromValue || toValue) {
      const from = moment(fromValue)
        .startOf("day")
        .tz("Asia/Kolkata")
        .valueOf();
      const to = moment(toValue).endOf("day").tz("Asia/Kolkata").valueOf();

      query[queryKey] = {
        $gte: from,
        $lte: to,
      };
    }
  }

  //formik
  const formik = useFormik({
    initialValues: {
      products: [],
      from: null,
      to: null,
      includeInSearch: "no",
      createdBy: "",
      group: [],
      isMeetingSchedule: "",
      isCallSchedule: "",
      callScheduleFrom: null,
      callScheduleTo: null,
    },
    onSubmit: async (values) => {
      try {
        let query = {
          products: { $in: values.products },
        };

        if (values.createdBy) {
          query["createdBy._id"] = values.createdBy;
        }

        if (values.group) {
          query["group._id"] = { $in: values.group };
        }

        if (values.isMeetingSchedule) {
          query["isMeetingSchedule"] = values.isMeetingSchedule;
        }

        if (values.isCallSchedule) {
          query["isCallSchedule"] = values.isCallSchedule;
        }

        if (
          (values.from && values.from !== null) ||
          (values.to && values.to !== null)
        ) {
          setDateRangeQuery(values.from, values.to, "meetingDate", query);
        }

        if (
          (values.callScheduleFrom && values.callScheduleFrom !== null) ||
          (values.callScheduleTo && values.callScheduleTo !== null)
        ) {
          setDateRangeQuery(
            values.callScheduleFrom,
            values.callScheduleTo,
            "callScheduleDate",
            query
          );
        }

        if (values.includeInSearch === "yes") {
          if (activeBtnIndex !== -1 && currentStatusState) {
            await dispatch(
              fetchLeads({
                ...query,
                populate: true,
                sort: { leadNumber: 1 },
                "status.name": currentStatusState,
                page,
              })
            );
          } else if (activeBtnIndex === -1) {
            await dispatch(
              fetchLeads({
                ...query,
                populate: true,
                sort: { leadNumber: 1 },
                page,
              })
            );
          }
        } else {
          if (activeBtnIndex !== -1 && currentStatusState) {
            await dispatch(
              fetchLeads({
                ...query,
                populate: true,
                sort: { leadNumber: 1 },
                "status.name": currentStatusState,
                editStatus: "approved",
                page,
              })
            );
          } else if (activeBtnIndex === -1) {
            await dispatch(
              fetchLeads({
                ...query,
                populate: true,
                sort: { leadNumber: 1 },
                editStatus: "approved",
                page,
              })
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    leadFilter();
  }, [activeBtnIndex, currentStatusState, page]);

  const leadFilter = (search) => {
    try {
      if (formik.values.includeInSearch === "yes") {
        let query = {};
        if (formik.values.products) {
          query["products"] = { $in: formik.values.products };
        }
        if (formik.values.createdBy) {
          query["createdBy._id"] = formik.values.createdBy;
        }
        if (formik.values.group) {
          query["group._id"] = { $in: formik.values.group };
        }
        if (formik.values.isMeetingSchedule) {
          query["isMeetingSchedule"] = formik.values.isMeetingSchedule;
        }

        if (formik.values.isCallSchedule) {
          query["isCallSchedule"] = formik.values.isCallSchedule;
        }

        if (
          (formik.values.from && formik.values.from !== null) ||
          (formik.values.to && formik.values.to !== null)
        ) {
          setDateRangeQuery(
            formik.values.from,
            formik.values.to,
            "meetingDate",
            query
          );
        }

        if (
          (formik.values.callScheduleFrom &&
            formik.values.callScheduleFrom !== null) ||
          (formik.values.callScheduleTo &&
            formik.values.callScheduleTo !== null)
        ) {
          setDateRangeQuery(
            formik.values.callScheduleFrom,
            formik.values.callScheduleTo,
            "callScheduleDate",
            query
          );
        }

        if (activeBtnIndex !== -1 && currentStatusState) {
          dispatch(
            fetchLeads({
              ...query,
              populate: true,
              sort: { leadNumber: 1 },
              "status.name": currentStatusState,
              page,
              search,
            })
          );
        } else if (activeBtnIndex === -1) {
          dispatch(
            fetchLeads({
              ...query,
              populate: true,
              sort: { leadNumber: 1 },
              page,
              search,
            })
          );
        }
      } else {
        if (activeBtnIndex !== -1 && currentStatusState) {
          dispatch(
            fetchLeads({
              populate: true,
              editStatus: "approved",
              sort: { leadNumber: 1 },
              "status.name": currentStatusState,
              page,
              search,
            })
          );
        } else if (activeBtnIndex === -1) {
          dispatch(
            fetchLeads({
              populate: true,
              editStatus: "approved",
              sort: { leadNumber: 1 },
              page,
              search,
            })
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(fetchStatuses({ sort: { order: 1 } }));
    dispatch(fetchEmployees({ populate: true }));
    dispatch(fetchGroups());
  }, []);

  const replacePlaceholder = (inputStr, replacementArray) => {
    let replacedStr = inputStr;

    for (let i = 0; i < replacementArray.length; i++) {
      replacedStr = replacedStr.replace("{#var#}", replacementArray[i]);
    }

    return replacedStr;
  };

  //CsvDownload
  const handleCsvDownload = async () => {
    try {
      setIsDownloading(true);
      let data = {
        populate: true,
        limit: 5000,
        editStatus: "approved",
        sort: { leadNumber: 1 },
        page,
      };

      if (activeBtnIndex !== -1 && currentStatusState) {
        data["status.name"] = currentStatusState;
      }

      const stringData = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`/lead?${stringData}`);

      if (resp?.data?.data?.docs?.length > 0) {
        let mainData = resp?.data?.data?.docs.map((lead, index) => {
          let {
            name,
            contactNumbers,
            emails,
            product,
            meetingDate,
            meetingTime,
            leadNumber,
            status,
            editStatus,
            reference,
            editSummary,
            notes,
            createdBy,
          } = lead;
          const numbersCon = contactNumbers
            .map((contactNumber) => contactNumber)
            .join(", ");

          const emailCon = emails.map((email) => email).join(", ");
          const productCon = product.map((n) => n.name).join(", ");
          return {
            srNo: `${index + 1}`,
            leadNumber: leadNumber,
            name: name,
            contactNumbers: numbersCon,
            emails: emailCon,
            status: status?.name,
            products: productCon,
            editStatus: editStatus,
            meetingDate: meetingDate
              ? moment(meetingDate).format("DD/MM/YYYY")
              : null,
            meetingTime: meetingDate
              ? moment(meetingTime).format("hh:mm a")
              : null,
            reference: reference?.name,
            editSummary: editSummary,
            notes: notes,
            createdBy: createdBy
              ? `${createdBy?.firstName} ${createdBy?.lastName}`
              : null,
          };
        });

        exportCsv(mainData);
      }
    } catch (error) {
      console.log("Error", error);
      toast.error("Failed to download csv file");
    } finally {
      setIsDownloading(false);
    }
  };

  //debouncing search
  const debouncedSearch = useCallback(
    _.debounce((search) => {
      leadFilter(search);
    }, 300),
    [currentStatusState, lead]
  );

  const cols = [
    {
      Header: "Lead Number",
      accessor: "leadNumber",
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Group",
      Cell: ({ row }) => {
        return row?.original?.group?.name ?? "---";
      },
      accessor: "group.name",
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Name",
      accessor: "name",
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Phone Numbers",
      Cell: ({ row }) => {
        return (
          <ul className="flex flex-col gap-3">
            {row?.original?.contactNumbers?.map((contactNumber, i) => (
              <li key={i}>
                <a href={`tel:+${contactNumber}`}>{contactNumber}</a>
              </li>
            ))}
          </ul>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: ({ row }) => {
        return (
          <div className="flex gap-2  w-[170px]">
            <div
              className="relative group cursor-pointer w-full"
              onClick={(e) => {
                e.stopPropagation();
                setIsLeadNotesModalOpen(true);
                setLeadNotesData(row.original);
              }}
            >
              <p className="text-wrap w-full flex flex-row  hover:text-indigo-500 items-center justify-left group">
                <p className="line-clamp-3">{row?.original?.notes}</p>

                <span>
                  <ArrowUpRight
                    size={18}
                    className="text-slate-400 hover:text-indigo-500 group-hover:text-indigo-500"
                  />
                </span>
              </p>

              <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 hidden group-hover:block ">
                <div className="bg-gray-400 text-white text-xs rounded py-1 px-2 whitespace-nowrap ">
                  Click To View Full Notes
                </div>
                <div className="w-2.5 h-2.5 bg-gray-400 transform rotate-45 absolute top-1/2 -translate-y-1/2 -left-1.5"></div>
              </div>
            </div>
          </div>
        );
      },
      cellStyle: {
        width: "100%",
      },
    },
    {
      Header: "Meeting Date",
      Cell: ({ row }) => {
        return (
          <div className="flex text-center">
            {row?.original?.meetingDate
              ? moment(row?.original?.meetingDate).format("DD/MM/YYYY")
              : null}
          </div>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Meeting Time",
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.meetingTime
              ? moment(row?.original?.meetingTime).format("hh:mm a")
              : null}
          </span>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Call Schedule Date",
      Cell: ({ row }) => {
        return (
          <div className="flex text-center">
            {row?.original?.callScheduleDate
              ? moment(row?.original?.callScheduleDate).format("DD/MM/YYYY")
              : null}
          </div>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Call Schedule Time",
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.callScheduleTime
              ? moment(row?.original?.callScheduleTime).format("hh:mm a")
              : null}
          </span>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Products",
      Cell: ({ row }) => {
        return (
          <ul>
            {row?.original?.product?.map((n) => (
              <li key={n?._id}>{n.name}</li>
            ))}
          </ul>
        );
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Reference",
      Cell: ({ row }) => {
        return <span>{row?.original?.reference?.name}</span>;
      },
      cellStyle: {
        width: "20%",
      },
    },
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <div className="flex flex-row items-center gap-2 cursor-pointer">
            <a>
              <Edit
                size={22}
                color="#292ba9"
                className="cursor-pointer"
                onClick={async (e) => {
                  e.stopPropagation();
                  // navigate(`/lead/edit/${row?.original?._id}`);
                  window.open(`/lead/edit/${row?.original?._id}`, "_blank");
                }}
              />
            </a>
            <Eye
              size={22}
              color="#408bff"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                navigate(`/leadHistory/${row?.original?.leadNumber}`);
              }}
            />
            <span
              onClick={async (e) => {
                e.stopPropagation();

                const template = "Hello, {#var#}, We have a meeting";
                const message = replacePlaceholder(template, [
                  `${row?.original?.name}`,
                ]);
                const linkOption = {
                  phone: `${row?.original?.contactNumbers[0]}`,
                  text: message,
                };
                setLinkOption(linkOption);
                setOpenMessageBox(true);
              }}
            >
              <img
                src={whatsappIcon}
                alt="Whatsapp"
                className="w-[30px] h-[20px]"
              />
            </span>
            <Mail
              size={22}
              color="#c82a2a"
              className="cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                const subject = encodeURIComponent("Hello");
                const body = encodeURIComponent(
                  "I would like to inquire about..."
                );
                const mailtoLink = `mailto:${row?.original?.emails[0]}?subject=${subject}&body=${body}`;
                window.location.href = mailtoLink;
              }}
            />
            <Trash
              size={22}
              color="red"
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  const stringData = QueryString.stringify({
                    leadNumber: row?.original?.leadNumber,
                  });
                  const resp = await authAxiosInstance.get(
                    `/lead?${stringData}`
                  );
                  if (resp?.data?.data?.docs?.length > 0) {
                    const leadArray = resp.data.data.docs;
                    //delete all data with same leadNumber
                    for (let i = 0; i < leadArray.length; i++) {
                      const { _id } = leadArray[i];
                      await dispatch(deleteLead({ id: _id }));
                    }
                  }
                  // fetch all current data
                  if (activeBtnIndex === -1) {
                    // If the search is initiated without a status filter
                    dispatch(
                      fetchLeads({
                        editStatus: "approved",
                        populate: true,
                        sort: { leadNumber: 1 },
                        page,
                      })
                    );
                  } else {
                    // If a status is selected, include it in the search
                    dispatch(
                      fetchLeads({
                        editStatus: "approved",
                        populate: true,
                        "status.name": currentStatusState,
                        sort: { leadNumber: 1 },
                        page,
                      })
                    );
                  }
                } catch (error) {
                  console.log(error);
                  toast.error("Error Deleting Lead");
                }
              }}
            />
          </div>
        );
      },
      cellStyle: {
        width: "40%",
      },
    },
  ];

  const leadMemo = useMemo(() => (lead ? lead?.docs : []), [lead]);
  const colsMemo = useMemo(() => (cols ? cols : []), [cols]);

  return (
    <PageWithCard heading="View Lead">
      <MessageTextModal
        linkOption={linkOption}
        openMessageBox={openMessageBox}
        setOpenMessageBox={setOpenMessageBox}
      />
      <ViewLeadInDetails
        modalOpen={isLeadNotesModalOpen}
        setModalOpen={setIsLeadNotesModalOpen}
        data={leadNotesData}
      />

      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col gap-3 p-4 border mb-4 rounded-sm"
      >
        <div className="grid md:grid-cols-10 gap-3">
          <div className="col-span-1 md:col-span-2">
            <FormikSelectGroup
              formik={formik}
              label="Universal Search"
              name="includeInSearch"
              options={LEAD_FILTER_OPTION}
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <FormikAsyncMultiSelect
              formik={formik}
              label="Select Product"
              name="products"
              getOptions={async (value) => {
                try {
                  const query = { search: value };
                  const string = QueryString.stringify(query);
                  const productResp = await authAxiosInstance.get(
                    `product?${string}`
                  );
                  const options = productResp.data.data.docs.map((el) => ({
                    label: el.name,
                    value: el._id,
                  }));
                  return options;
                } catch (error) {
                  console.log(error);
                }
              }}
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <FormikSelectGroup
              formik={formik}
              label="Meeting Schedule Status"
              name="isMeetingSchedule"
              options={LEAD_MEETING_SCHEDULE}
            />
          </div>

          <div className="col-span-1 md:col-span-4 flex flex-row gap-5">
            <div>
              <FormikInputDateGroup
                formik={formik}
                label="Meeting Date From"
                name="from"
              />
            </div>
            <div>
              <FormikInputDateGroup
                formik={formik}
                label="Meeting Date To"
                name="to"
              />
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-10 gap-3">
          <div className="col-span-1 md:col-span-2">
            <FormikMultiSelect
              formik={formik}
              label="Group"
              options={generateOptions({
                array: group?.docs?.map((ele) => ({
                  name: ele.name,
                  value: ele._id,
                })),
                labelField: "name",
                valueField: "value",
              })}
              name="group"
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <FormikSelectGroup
              formik={formik}
              label="Created By"
              options={generateOptions({
                array: employees?.docs?.map((ele) => ({
                  name: `${ele.firstName} ${ele.lastName}`,
                  value: ele._id,
                })),
                labelField: "name",
                valueField: "value",
              })}
              name="createdBy"
            />
          </div>
          <div className="col-span-1 md:col-span-2">
            <FormikSelectGroup
              formik={formik}
              label="Call Schedule Status"
              name="isCallSchedule"
              options={LEAD_MEETING_SCHEDULE}
            />
          </div>
          <div className="col-span-1 md:col-span-4 flex flex-row  gap-5">
            <div>
              <FormikInputDateGroup
                formik={formik}
                label="Call Schedule Date From"
                name="callScheduleFrom"
              />
            </div>
            <div>
              <FormikInputDateGroup
                formik={formik}
                label="Call Schedule Date To"
                name="callScheduleTo"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-3">
          <PrimaryButton type="submit" className="py-1">
            Submit
          </PrimaryButton>
          <SecondaryButton
            className="py-1"
            type="button"
            onClick={async (e) => {
              e.stopPropagation();
              formik.setFieldValue("products", []);
              formik.setFieldValue("createdBy", "");
              formik.setFieldValue("from", null);
              formik.setFieldValue("to", null);
              formik.setFieldValue("group", []);
              formik.setFieldValue("callScheduleFrom", null);
              formik.setFieldValue("callScheduleTo", null);
              formik.setFieldValue("isMeetingSchedule", "");
              formik.setFieldValue("isCallSchedule", "");
              formik.setFieldValue("includeInSearch", "no");

              if (activeBtnIndex !== -1 && currentStatusState) {
                await dispatch(
                  fetchLeads({
                    populate: true,
                    sort: { leadNumber: 1 },
                    "status.name": currentStatusState,
                    editStatus: "approved",
                    page,
                  })
                );
              } else if (activeBtnIndex === -1) {
                await dispatch(
                  fetchLeads({
                    populate: true,
                    sort: { leadNumber: 1 },
                    editStatus: "approved",
                    page,
                  })
                );
              }
            }}
          >
            Clear Filter
          </SecondaryButton>
        </div>
      </form>

      {
        <div className="flex flex-row items-center gap-4 mb-4 overflow-auto scrollable-content overscroll-x-auto p-4 border rounded-sm">
          {/* Manually adding a Search button at index -1 */}
          <div>
            <button
              className={
                activeBtnIndex === -1
                  ? `btn bg-indigo-500 hover:bg-indigo-600 text-white py-1 text-nowrap`
                  : `btn bg-white hover:bg-gray text-indigo-500 border border-gray-300 py-1 text-nowrap`
              }
              onClick={(e) => {
                e.stopPropagation();
                setActiveBtnIndex(-1);
                setCurrentStatusState(null); // Reset the status
                setPage(1);
              }}
            >
              Search
            </button>
          </div>

          {/* Rendering the status buttons */}
          {status?.docs?.map((item, index) => (
            <div key={item?._id}>
              <button
                className={
                  activeBtnIndex === index
                    ? `btn bg-indigo-500 hover:bg-indigo-600 text-white py-1 text-nowrap`
                    : `btn bg-white hover:bg-gray text-indigo-500 border border-gray-300 py-1 text-nowrap`
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveBtnIndex(index);
                  setCurrentStatusState(item.name);
                  setPage(1);
                }}
              >
                {item.name}
              </button>
            </div>
          ))}
        </div>
      }

      <TableWithHeadingAndGlobalSearch
        columns={colsMemo}
        data={leadMemo?.length ? leadMemo : []}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
        loading={loading}
        heading={
          <>
            <div className="flex flex-row items-center gap-4">
              <span>Leads</span>
              {isDownloading ? (
                <ClipLoader />
              ) : (
                <SecondaryButton type="button" onClick={handleCsvDownload}>
                  <Download size={18} className="mr-2" /> Leads Csv
                </SecondaryButton>
              )}
            </div>
          </>
        }
      />
      <PaginationClassic paginationDetails={lead} setPage={setPage} />
    </PageWithCard>
  );
};

export default ViewLead;
