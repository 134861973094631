import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../utils/axiosConfig";
import { getLocal, removeLocal, setLocal } from "../../../Common/Stores";
import jwt_decode from "jwt-decode";

const initialState = {
  user: {},
  error: null,
  jwt: null,
  loading: false,
};
export const postLogin = createAsyncThunk(
  "auth/postLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`users/employee/login`, data);
      setLocal("access_token", response.data.accessToken);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue({
        error: "Some error has occured please try again",
      });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    checkJwt: (state) => {
      state.loading = true;
      const jwt = getLocal("access_token");
      if (jwt) {
        const {
          _id,
          phone,
          name,
          firstName,
          lastName,
          role,
          exp,
          username,
          organization,
          pagesAllowed,
        } = jwt_decode(jwt);
        if (Math.floor(exp - Math.floor(Date.now() / 1000)) < 20) {
          removeLocal("access_token");
          state.loading = false;
          state.error = null;
          state.user = {};
        } else {
          state.loading = false;
          state.user = {
            _id,
            phone,
            name,
            role,
            username,
            firstName,
            lastName,
            organization,
            pagesAllowed,
          };
          state.jwt = jwt;
          state.error = null;
        }
      } else {
        state.user = {};
        state.jwt = null;
        state.loading = false;
      }
    },
    logout: (state) => {
      state.loading = true;
      removeLocal("access_token");
      state.user = {};
      state.loading = false;
      state.error = null;
      state.jwt = null;
    },
  },
  extraReducers: {
    [postLogin.pending]: (state, action) => {
      state.loading = true;
    },
    [postLogin.fulfilled]: (state, action) => {
      state.loading = false;
      setLocal("access_token", action.payload.data.accessToken);
      state.user = action.payload.data.user;
    },
    [postLogin.rejected]: (state, action) => {
      state.loading = false;

      state.error = action.payload.error;
    },
  },
});

// export const { handleLogin } = authSlice.actions;

export const getAuth = (state) => state.auth;
export const { checkJwt, logout } = authSlice.actions;
export default authSlice.reducer;
